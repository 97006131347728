import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Footer } from 'components/Footer'
import { NavBar } from 'components/NavBar'
import { UpdatedRpcModalAnnouncement } from 'components/UpdatedRpcAnnouncement'
import { useThemeColors } from 'hooks/useThemeColors'
import { PAGE_OFFSET_X } from 'theme/constants'

type BaseLayoutProps = {
  children?: React.ReactNode
}

export const BaseLayout = ({ children }: BaseLayoutProps) => {
  const COLORS = useThemeColors()

  return (
    <Flex minH="100vh" flexDir="column" justifyContent="space-between">
      <Box bgColor={COLORS.bgSecondary}>
        <NavBar />
        <UpdatedRpcModalAnnouncement />
        <Box
          px={PAGE_OFFSET_X}
          pt={{ base: 4, md: 12 }}
          bgColor={COLORS.bgPrimary}
          borderTopLeftRadius={20}
          borderTopRightRadius={20}
        >
          {children}
        </Box>
      </Box>
      <Footer />
    </Flex>
  )
}
