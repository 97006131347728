import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { Announcement } from 'components/Announcement'
import { CopyToClipboardButton } from 'components/CopyToClipboardButton'
import { Modal } from 'components/Modal'
import { FULL_ZIRCUIT_NETWORK_NAME, zircuit } from 'constants/network'
import { env } from 'env.client'
import { useThemeColors } from 'hooks/useThemeColors'

const RPC_URL =
  ['mainnet', 'testnet'].includes(env.NEXT_PUBLIC_NETWORK) && zircuit.rpcUrls.default.http[0]

export const UpdatedRpcModalAnnouncement = () => {
  const COLORS = useThemeColors()
  const { t } = useTranslation(['common'])
  const [isAddUpdatedRpcModalOpen, setIsAddUpdatedRpcModalOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    const hasModalBeenShown = localStorage.getItem('updatedRpcModalShown')

    if (!hasModalBeenShown) {
      setIsAddUpdatedRpcModalOpen(true)
      localStorage.setItem('updatedRpcModalShown', 'true')
    }
  }, [])

  if (!RPC_URL) {
    return null
  }

  return (
    <>
      <Modal
        variant="infoZircuit"
        isOpen={isAddUpdatedRpcModalOpen}
        onClose={() => {
          void setIsAddUpdatedRpcModalOpen(false)
        }}
        title="Update your default RPC url"
      >
        <Text variant="text3regular" mb={6}>
          {t('UpdatedRpcAnnouncement.ModalMessage', {
            networkName: FULL_ZIRCUIT_NETWORK_NAME,
          })}
        </Text>
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Box>
            <Text variant="text3medium">{t('UpdatedRpcAnnouncement.RpcUrl')}</Text>
            <Text variant="text3regular">{RPC_URL}</Text>
          </Box>
          <CopyToClipboardButton text={RPC_URL} />
        </Flex>
        <Divider />
      </Modal>
      <Announcement>
        <Flex gap={1} wrap="wrap">
          <Text>
            {t('UpdatedRpcAnnouncement.RpcUrlHasBeenUpdatedTo', {
              networkName: FULL_ZIRCUIT_NETWORK_NAME,
            })}
          </Text>
          <Text color={COLORS.zircuitDark} variant="text1medium">
            {RPC_URL}
          </Text>
          <CopyToClipboardButton text={RPC_URL} iconColor={COLORS.zircuitDark} />
        </Flex>
      </Announcement>
    </>
  )
}
